<template>
<error-content code="404" :desc="$t('Oh～您的页面好像飞走了～')" :src="src"></error-content>
</template>

<script>
import errorContent from './error-content.vue'

export default {
  name: 'error_404',
  components: {
    errorContent
  },
  data () {
    return {
      src: 'https://file.40017.cn/zhuketong/zhuketong/image/oms/page_404.png'
    }
  }
}
</script>
